@import url(/fonts/material-icons.css);

:root {
	--adm-black: #2D2E33;
	--adm-teal-light: #31C4C4;
	--adm-teal: #2BABB6;
	--adm-blue: #2FA7CA;
	--adm-grey-light: #F2F3F5;
	--adm-grey: #6E717D;
	--adm-grey-dark: #353942;
	--adm-red: #CA445A;

	--app-min-width: 1500px;
	--chbx-s: 18px;

	--container-w: 1600px;
	--container-padding: 20px;

	--n-container-wdt: 1322px;
	--n-container-hz-pad: 0px;

	--section-spacing: 50px;

	--navbar-h: 94px;
	--navbar-offset: 16px;

	--p-color: #455a64;
	--p-dark-color: #29434e;
	--p-light-color: #718792;
	--s-color: #ff8f00;
	--s-dark-color: #c43e00;
	--dark-green: #0A5D00;
	--green: #4caf50;
	--promo-green: #E9F8F5;
	--yellow: #ffae42;
	--light-green: #c8e6c9;
	--lighter-green: #e8f5e9;
	--red: #e53935;
	--strong-red: #e91919;

	--link-color: #039be5;
	--check-color: #f0f0f0;
	--border-color: #ccc;
	--border-dark-color: #aaa;

	--dark-blue: #365a71;

	/* --new-font: #878c9f; */
	/* --new-font: #a3a7b6; */
	--new-font: #b7c2cd;

	--black: #000;
	--blue: #365a71;
	--box-shadow-radius: 16px;
	--box-shadows: #e5e5e5;
	--teal: #00b5ad;
	--gray: #f6f7fa;
	--gray-1: #e6e7ea;
	--background-grey: #f2f2fa;
	--payu: #a6c307;
	--subtle-green: #d6f2ef;
	--subtle-green-alt: #f6fcfb;
	--subtle-gray: #f9f9f9;
	--subtle-gray-alt: #fafafc;
	--white: #fff;

	--border-radius: 20px;

	--car-search-field-h: 57px;
	--car-search-input-h: 36px;
	--car-search-link-w: 151px;
	--car-search-w: 1200px;

	--input-border-c: #d2d5e2;

	--ck-color-base-foreground: #fff !important;
	--ck-color-base-background: var(--gray) !important;
	--ck-color-base-border: var(--input-border-c) !important;
	--ck-border-radius: 12px !important;

	--gray-0: #fafbfc;
	--gray-2: #e1e4e8;
	--gray-4: #959da5;
	--gray-6: #586069;
	--gray-8: #2f363d;
	--gray-9: #242c33;
	--gray-10: #1b1f23;
}

* {
	font-family: var(--font-dm-sans), serif;
	box-sizing: border-box;
	color: var(--dark-blue);
}

.ck-editor__main a {
	font-weight: bold;
	text-decoration: underline;
}

html,
body {
	color: var(--new-font);
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	margin: 0;
	padding: 0;
	scroll-padding-top: calc(var(--navbar-h) + var(--navbar-offset));
}

html {
	font-size: 1px;
}

body {
	font-size: 14rem;
}

.container {
	max-width: var(--container-w);
	padding: 0 var(--container-padding);
	margin: auto;
}

.relative {
	position: relative;
}

.bt {
	background: white;
	border: none;
	cursor: pointer;
}

h1 {
	font-size: 20rem;
}

h2 {
	font-size: 18rem;
}

h3 {
	font-size: 16rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
	color: var(--black);
}

blockquote {
	border-left: 3px solid var(--input-border-c);
	line-height: 1.6;
	margin-left: 0;
	padding: 10px;
}

a {
	color: inherit;
	text-decoration: none;
}

hr {
	background: var(--box-shadows);
	border: none;
	display: block;
	height: 1px;
	margin-block: 12px;
	width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-webkit-appearance: textfield;
	        appearance: textfield;
	-moz-appearance: textfield;
}

.flash-message,
.flash-message>i,
.loading-dialog,
.loading-dialog>i,
.loading-dialog>span {
	color: white;
}

.loading-dialog,
.flash-message {
	position: fixed;
	padding: 20px 50px;
	right: 60px;
	text-align: center;
	top: 100px;
	transition: all .3s ease-in-out;
	z-index: 10000;
}

.loading-dialog.message,
.flash-message.message {
	background: var(--teal);
}

.flash-message.error {
	background: var(--red);
}

.flash-message.warning {
	background: var(--yellow);
}

.flash-message i {
	position: absolute;
	right: 15px;
	top: 17px;
}

.clickable {
	cursor: pointer;
	opacity: .8;
}

.clickable:hover {
	opacity: 1;
}

.min-height {
	min-height: calc(100vh - 550px);
}

.max-width {
	max-width: 800px;
}

progress[value] {
	-webkit-appearance: none;
	-moz-appearance: none;
	     appearance: none;
	border-radius: 6px;
	height: 12px;
	overflow: hidden;
}

::-webkit-progress-bar {
	background: var(--gray);
	border: 1px dashed var(--input-border-c);
}

::-webkit-progress-value {
	background: var(--teal);
	-webkit-transition: width .3s;
	transition: width .3s;
}

.loading-dialog {
	padding-left: 32px;
}

.anim-rotate {
	animation: 1s anim-rotate infinite ease-in-out;
}

.grecaptcha-badge {
	visibility: hidden;
}

.ph-normal::placeholder {
	font-size: 14rem;
	font-weight: 300 !important;
}

[role="button"] {
	cursor: pointer;
}

@keyframes anim-rotate {
	from {
		transform: rotate(0deg)
	}

	to {
		transform: rotate(360deg)
	}
}

@media screen and (max-width: 1350px) {
	:root {
		--n-container-hz-pad: 20px;
	}
}

@media screen and (min-width: 801px) {
	h1 {
		font-size: 34rem;
	}

	h2 {
		font-size: 26rem;
	}

	h3 {
		font-size: 19rem;
	}
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_4201d4';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_4201d4';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_4201d4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_4201d4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_4201d4';src: local("Arial");ascent-override: 94.90%;descent-override: 29.66%;line-gap-override: 0.00%;size-adjust: 104.53%
}.__className_4201d4 {font-family: '__DM_Sans_4201d4', '__DM_Sans_Fallback_4201d4';font-style: normal
}.__variable_4201d4 {--font-dm-sans: '__DM_Sans_4201d4', '__DM_Sans_Fallback_4201d4'
}

